import React from 'react'
import { SectionFeaturedUseCases } from '../../../types/sections'
import { Box, Button, Flex, Heading, Image } from '../../../atoms'
import { SECTION_MARGIN } from '../../../utils/constants'
import { Col, Grid, Row } from 'react-styled-flexboxgrid'
import { UseCaseCard } from '../../resources/use-cases'
import { FeaturedUseCasesCol, FeaturedUseCasesRow } from './FeaturedUseCases.styled'

function FeaturedUseCases(props: SectionFeaturedUseCases): JSX.Element {
  const { sectionId, id, headline, icon, button, useCases } = props
  return (
    <Box as={'section'} id={sectionId ?? id} my={SECTION_MARGIN}>
      <Grid>
        <Row between={'xs'}>
          <Col xs={12} md={4}>
            <Flex flexDirection={'column'} gap={[4, 4, 6]}>
              {icon && (
                <Box display={['none', 'none', 'block']}>
                  <Box>
                    <Image alt={icon.alt ?? headline} title={icon.title ?? headline} image={icon.gatsbyImageData} />
                  </Box>
                </Box>
              )}
              <Box>
                <Heading as={'h2'} type='heading1'>
                  {headline}
                </Heading>
              </Box>
              {button && button[0] && (
                <Box>
                  <Button link={button[0].link} label={button[0].label} buttonType={button[0].buttonType} />
                </Box>
              )}
            </Flex>
          </Col>
          <Col xs={12} md={7}>
            {useCases.length > 0 && (
              <Box mt={[6, 6, 0]}>
                <FeaturedUseCasesRow className={'noscrollBar'} style={{ rowGap: '32px' }}>
                  {useCases.map((useCase) => (
                    <FeaturedUseCasesCol key={useCase.id} xs={10} sm={5} md={6}>
                      <UseCaseCard {...useCase} />
                    </FeaturedUseCasesCol>
                  ))}
                </FeaturedUseCasesRow>
              </Box>
            )}
          </Col>
        </Row>
      </Grid>
    </Box>
  )
}

export default FeaturedUseCases
