import React from 'react'
import { SectionPush } from '../../../types/sections'
import { Box } from '../../../atoms'
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { SECTION_MARGIN } from '../../../utils/constants'
import { PushComponent } from '../../commons'

function Push(props: SectionPush): JSX.Element {
  const { id, sectionId, push } = props

  return (
    <Box as={'section'} id={sectionId ?? id} my={SECTION_MARGIN}>
      <Grid>
        <Row>
          <Col xs={12}>
            <PushComponent {...push} />
          </Col>
        </Row>
      </Grid>
    </Box>
  )
}

export default Push
