import React from 'react'
import { Link } from 'gatsby'
import { IUseCase } from '../../../../types/resources'
import { ButtonProps } from '../../../../types/buttons'
import { PageInfoLinkProps } from '../../../../types/pages'
import { Box, Flex, Heading, Image, Tag, TemplateTag } from '../../../../atoms'
import { Col, Grid, Row } from 'react-styled-flexboxgrid'
import { StructuredTextContent } from '../../../commons/StructuredText/StructuredTextContent'
import { SECTION_MARGIN, SECTION_TOP_PADDING } from '../../../../utils/constants'
import StructuredTextSummary from '../../../commons/StructuredTextSummary/StructuredTextSummary'
import PostShare from '../../../blog/PostShare/PostShare'
import { getUrlToShare } from '../../../../utils/commons'
import PostSide from '../../../blog/PostSide/PostSide'
import { useLocale } from '../../../../hooks/useLocale'

interface UseCaseArticleProps extends Pick<IUseCase, 'content' | 'tags' | 'name' | 'integrations'> {
  tagLabel: string
  tagLink: PageInfoLinkProps
  articleSideHeadline: string
  articleSideButton: Array<ButtonProps>
  articleSideTableOfContentLabel: string
  articleSideShareLabel: string
}

const UseCaseArticle: React.FC<UseCaseArticleProps> = ({
  tagLabel,
  tagLink,
  content,
  tags,
  articleSideHeadline,
  articleSideButton,
  articleSideTableOfContentLabel,
  articleSideShareLabel,
  name,
  integrations,
}) => {
  const { localeSlug } = useLocale()

  return (
    <Box as={'section'} position={'relative'} pt={SECTION_TOP_PADDING} mb={SECTION_MARGIN}>
      <Grid>
        <Row>
          <Col xs={12} md={8}>
            <Flex flexDirection={'column'} gap={16}>
              <Link to={`/${localeSlug}${tagLink.slug}`}>
                <Tag light color='red' label={tagLabel} />
              </Link>
              <Heading type='heading1' as={'h1'}>
                {name}
              </Heading>
              <Flex gap={'10px'} flexWrap={'wrap'}>
                {integrations.map((integration, index) => (
                  <Box key={`icon-${index}`} maxWidth={24}>
                    <Image
                      image={integration.icon.gatsbyImageData}
                      alt={integration.icon.alt || integration.name}
                      title={integration.icon.title || integration.name}
                      objectFit='contain'
                    />
                  </Box>
                ))}
              </Flex>
            </Flex>
            <Box mt={[6, 7, 8]}>
              <StructuredTextContent content={content} />
            </Box>
            {tags.length > 0 && (
              <Flex flexWrap={'wrap'} gap={16} mt={[6, 7, 8]}>
                {tags.map((tag) => (
                  <TemplateTag key={tag.id} label={tag.name} />
                ))}
              </Flex>
            )}
          </Col>
          <Col xs={false} md={4}>
            <Flex flexDirection={'column'} alignItems={'stretch'} justifyContent={'space-between'} height={'100%'}>
              <Box flex={1} pb={32}>
                <Box position={'sticky'} top={'calc(90px + var(--banner-height))'}>
                  <StructuredTextSummary tableOfContentLabel={articleSideTableOfContentLabel} {...content} />
                  <Box mt={24}>
                    <PostShare label={articleSideShareLabel} urlToShare={getUrlToShare()} />
                  </Box>
                </Box>
              </Box>
              <Box>
                <PostSide headline={articleSideHeadline} button={articleSideButton} />
              </Box>
            </Flex>
          </Col>
        </Row>
      </Grid>
    </Box>
  )
}
export default UseCaseArticle
