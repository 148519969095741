import React from 'react'
import { IUseCaseCard } from '../../../../types/resources'
import { useLocale } from '../../../../hooks/useLocale'
import { Link } from 'gatsby'
import { Box, Flex, Heading, Icons, Image, TemplateTag, Text } from '../../../../atoms'
import { Tag } from '../../../../atoms/Tags'

function UseCaseCard(props: IUseCaseCard): JSX.Element {
  const { name, pageInfo, tags, integrations, excerpt } = props

  const { localeSlug } = useLocale()
  return (
    <Link to={`/${localeSlug}${pageInfo.slug}`} style={{ height: '100%', display: 'block' }}>
      <Flex
        flexDirection={'column'}
        alignItems={'stretch'}
        justifyContent={'space-between'}
        p={[5, 5, 6]}
        border={'1px solid black'}
        boxShadow={'card'}
        borderRadius={8}
        width={'100%'}
        height={'100%'}
      >
        <Box flex={'1'}>
          <Box mb={3}>
            <Tag light label={'Use Case'} color={'red'} />
          </Box>
          <Heading as={'h3'} type={'heading3'}>
            {name}
          </Heading>

          {integrations.length > 0 && (
            <Flex mt={3} mb={3} flexWrap={'wrap'} gap={3}>
              {integrations.map((integration) => (
                <Box key={integration.id} flex={'0 0 24px'}>
                  <Image
                    alt={integration.icon.alt || ''}
                    title={integration.icon.title || ''}
                    image={integration.icon.gatsbyImageData}
                  />
                </Box>
              ))}
            </Flex>
          )}

          <Box mt={3}>
            <Text type={'small'}>{excerpt}</Text>
          </Box>
          {tags.length > 0 && (
            <Flex mt={4} flexWrap={'wrap'} gap={2}>
              {tags.map((tag) => (
                <TemplateTag key={tag.id} label={tag.name} />
              ))}
            </Flex>
          )}
        </Box>

        <Flex flex={'0 0 auto'} mt={4} justifyContent={'flex-end'}>
          <Icons.ArrowRight />
        </Flex>
      </Flex>
    </Link>
  )
}

export default UseCaseCard
