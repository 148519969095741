import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { PageContextProps, PageInfoLinkProps } from '../../types/pages'
import { ButtonProps } from '../../types/buttons'
import { IUseCase, IUseCaseCard } from '../../types/resources'
import { GatsbyImageBasicProps } from '../../types/images'
import { Layout, Seo } from '../../components/commons'
import { UseCaseArticle } from '../../components/resources/use-cases'
import Push from '../../components/sections/Push/Push'
import FeaturedUseCases from '../../components/sections/FeaturedUseCases'

interface UseCaseQuery {
  useCase: Pick<IUseCase, 'id' | 'name' | 'pageInfo' | 'meta' | 'tags' | 'integrations' | 'content' | 'push'>
  utils: {
    useCaseLabel: string
    useCasesLanding: PageInfoLinkProps
    useCasesIcon: GatsbyImageBasicProps
    useCasesAllButton: Array<ButtonProps>
    useCasesOtherLabel: string
  }
  datoCmsGlobal: {
    postPushHeadline: string
    postPushButton: Array<ButtonProps>
    postTableOfContentLabel: string
    postShareLabel: string
  }
  otherUseCases: {
    nodes: Array<IUseCaseCard>
  }
}

const UseCase: React.FC<PageProps<UseCaseQuery, PageContextProps>> = ({ data, pageContext }) => {
  const { useCase, utils, datoCmsGlobal, otherUseCases } = data

  return (
    <Layout
      pageId={pageContext.id}
      locale={pageContext.locale}
      allSlugLocales={pageContext.slugLocales}
      withoutPrefooter={useCase.pageInfo.hidePrefooter}
    >
      <Seo
        slug={useCase.pageInfo.slug}
        canonicalUrl={useCase.pageInfo.canonicalUrl}
        locale={pageContext.locale}
        allSlug={pageContext.slugLocales}
        publication={useCase.meta}
        content={useCase.pageInfo.seo}
        preventIndexing={useCase.pageInfo.preventIndexing}
      />
      <UseCaseArticle
        tagLabel={utils.useCaseLabel}
        tagLink={utils.useCasesLanding}
        name={useCase.name}
        integrations={useCase.integrations}
        tags={useCase.tags}
        content={useCase.content}
        articleSideHeadline={datoCmsGlobal.postPushHeadline}
        articleSideButton={datoCmsGlobal.postPushButton}
        articleSideShareLabel={datoCmsGlobal.postShareLabel}
        articleSideTableOfContentLabel={datoCmsGlobal.postTableOfContentLabel}
      />
      {useCase.push && <Push id={''} model={{ apiKey: 's_push' }} sectionId={''} push={useCase.push} />}
      {otherUseCases.nodes.length > 0 && (
        <FeaturedUseCases
          sectionId={''}
          icon={utils.useCasesIcon}
          headline={utils.useCasesOtherLabel}
          button={utils.useCasesAllButton}
          useCases={otherUseCases.nodes}
          id={''}
          model={{ apiKey: 's_featured_use_cases_list' }}
        />
      )}
    </Layout>
  )
}

export const query = graphql`
  query UseCaseQuery($locale: String!, $id: String) {
    datoCmsGlobal(locale: $locale) {
      postPushHeadline
      postPushButton {
        ...buttonFragment
      }
      postTableOfContentLabel
      postShareLabel
    }

    utils: datoCmsResourcesGlobal(locale: $locale) {
      useCaseLabel
      useCasesLanding: useCasesPageInfo {
        ...pageInfoLinkFragment
      }
      useCasesIcon {
        alt
        title
        gatsbyImageData(layout: CONSTRAINED, width: 80, placeholder: NONE)
      }
      useCasesAllButton {
        ...buttonFragment
      }
      useCasesOtherLabel
    }

    otherUseCases: allDatoCmsUseCase(
      locale: $locale
      limit: 3
      sort: { fields: meta___firstPublishedAt, order: DESC }
      filter: { id: { ne: $id } }
    ) {
      nodes {
        ...useCaseCardFragment
      }
    }

    useCase: datoCmsUseCase(locale: $locale, id: { eq: $id }) {
      id
      name
      meta {
        firstPublishedAt
        publishedAt
        updatedAt
      }
      pageInfo {
        ...pageInfoFragment
      }
      tags {
        ...resourceTagFragment
      }
      content {
        value
        links
        blocks {
          __typename
          ...blockImageFragment
          ...blockVideoFragment
          ...blockGatedContentFragment
          ...buttonFragment
          ...blockTableFragment
        }
      }
      integrations {
        id
        name
        icon {
          alt
          title
          gatsbyImageData(layout: CONSTRAINED, width: 120, height: 120, placeholder: NONE)
        }
      }
      push {
        ...pushFragment
      }
    }
  }
`

export default UseCase
